import React from "react"

import Layout from "../components/resuable/layout/layout";
import SEO from "../components/resuable/seo/seo"
import { useStaticQuery, graphql } from "gatsby";
import FourZeroFourHero from "../components/page/404-components/four-zero-four-hero/four-zero-four-hero";
import FourZeroFourGif from "../components/page/404-components/four-zero-four-gif/four-zero-four-gif";

const NotFoundPage = () => {
  // const data = useStaticQuery(graphql`
  //   query NavbarLinksfor404 {
  //     site {
  //       siteMetadata {
  //         title
  //         menuLinks {
  //           name
  //           link
  //           target
  //         }
  //       }
  //     }
  //   }
  // `)
  return (
    <Layout theme="dark">
      <SEO title="404: Not found" />
      <FourZeroFourHero />
      <FourZeroFourGif />
    </Layout>
  )
}

export default NotFoundPage
