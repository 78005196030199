import React from 'react'
import styled from 'styled-components'
import Hero from '../../../resuable/hero/hero'
import { Link } from 'gatsby'

const StyledFourZeroFourHero = styled.div`
  
`

const FourZeroFourHero = (props) => {
  return (
    <StyledFourZeroFourHero>
      <Hero
        title="Page not found"
      >
        <span>This page does not exist or may have been moved! If you cannot find what you're looking for, please <Link to="/contact">Contact us</Link></span>
      </Hero>
    </StyledFourZeroFourHero>
  )
}

FourZeroFourHero.defaultProps = {

}

export default FourZeroFourHero