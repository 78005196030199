import React from 'react'
import styled from 'styled-components'
import Section from '../../../resuable/section/section'

const StyledFourZeroFourGif = styled.div`
  img {
    height: 300px;
  }
`

const FourZeroFourGif = (props) => {
  return (
    <Section>
      <StyledFourZeroFourGif>
        <img src="https://media.tenor.com/images/cd20c75be067089a49c4568e97db2a16/tenor.gif" alt="Funny Gif" />
      </StyledFourZeroFourGif>
    </Section>
  )
}

FourZeroFourGif.defaultProps = {

}

export default FourZeroFourGif